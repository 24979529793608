import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";
import "firebase/compat/messaging";
// OUR FIREBASE CONFIG
// export const firebaseConfig = {
//     apiKey: "AIzaSyBD_J6-ovEQtF6B2Oco5HG5t22glfb29ms",
//     authDomain: "gohaul-57af5.firebaseapp.com",
//     projectId: "gohaul-57af5",
//     storageBucket: "gohaul-57af5.appspot.com",
//     messagingSenderId: "677557987365",
//     appId: "1:677557987365:web:6e9ab9f72086f19da877cc"
// };

// CLIENT FIREBASE CONFIG
export const firebaseConfig = {
  apiKey: "AIzaSyCDdDMoJ7LjSCxIb_scN6TT8lPNzUl_0Uc",
  authDomain: "gohaultms.firebaseapp.com",
  projectId: "gohaultms",
  storageBucket: "gohaultms.appspot.com",
  messagingSenderId: "42609798867",
  appId: "1:42609798867:web:3d06f06e207d9d62cb2e26",
  measurementId: "G-SHH5SHLY4Z",
};

if (!firebase.apps.length) {
  // firebase.initializeApp({});
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that one
}

export default firebase;
